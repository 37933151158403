import React from 'react';


export default function () {

  return (
    <svg className="icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 30">
      <defs/>
      <g  clipPath="url(#clip0)">
        <path className="icon__path" fill="#798995" d="M20.602 3.621l-1.516-.628a1.172 1.172 0 01.897-2.165l1.515.628a1.172 1.172 0 11-.896 2.165zM15 0a1.172 1.172 0 110 2.344C8.005 2.344 2.344 8.004 2.344 15c0 6.995 5.66 12.656 12.656 12.656 6.995 0 12.656-5.66 12.656-12.656A1.172 1.172 0 0130 15c0 8.29-6.709 15-15 15-8.29 0-15-6.709-15-15C0 6.71 6.709 0 15 0zM24.53 6.63l-1.16-1.16a1.172 1.172 0 111.656-1.657l1.16 1.16a1.172 1.172 0 11-1.657 1.658zM28.538 11.549a1.172 1.172 0 01-1.531-.634l-.628-1.516a1.172 1.172 0 112.165-.897l.628 1.516a1.172 1.172 0 01-.634 1.53z"/>
      </g>
      <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0h30v30H0z" transform="matrix(-1 0 0 1 30 0)"/>
      </clipPath>
      </defs>
    </svg>
    )
  }
