
import React from 'react';


export default function () {

return (
      <svg className="icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 30">
        <path className="icon__path" fill="#E9E9E9" d="M5.455 21.114c0-.334.27-.605.605-.605h.144a.605.605 0 110 1.21H6.06a.605.605 0 01-.605-.605zM18.572 8.372a2.016 2.016 0 00.641 3.924h5.482a2.016 2.016 0 00.642-3.924.605.605 0 00-.388 1.147.805.805 0 01-.255 1.567h-5.48a.805.805 0 01-.255-1.567.605.605 0 10-.387-1.147zM9.514 26.076a.605.605 0 10-.388 1.147.805.805 0 01-.254 1.566H3.392a.805.805 0 01-.255-1.567.605.605 0 10-.387-1.146A2.016 2.016 0 003.392 30h5.48a2.016 2.016 0 00.642-3.924zm-5.046-.482c-1.079-2.12-1.747-3.609-1.747-4.48 0-1.88 1.53-3.41 3.41-3.41 1.88 0 3.41 1.53 3.41 3.41 0 .872-.668 2.36-1.746 4.48 0 0-.386.761-.405.802-.156.326-.446.934-1.259.934s-1.102-.608-1.258-.934l-.405-.802zm-.536-4.48c0 .756 1.132 2.98 1.615 3.93 0 0 .515 1.032.55 1.073.02.03.053.014.07 0 .034-.041.091-.161.13-.242.023-.049.419-.83.419-.83.483-.95 1.615-3.174 1.615-3.93 0-1.214-.987-2.2-2.2-2.2s-2.2.986-2.2 2.2zM28.623.605v4.34a.605.605 0 01-.606.605h-5.458v2.79h.48a.605.605 0 110 1.21h-2.17a.605.605 0 110-1.21h.48V4.957v-.013V.618.605c0-.334.27-.605.605-.605h6.063c.334 0 .605.271.605.605zm-1.211.606h-4.853v3.128h4.853V1.211zm-3.734 13.184H6.321a3.738 3.738 0 01-3.733-3.734A3.738 3.738 0 016.32 6.927h12.206a.605.605 0 000-1.21H6.321a4.95 4.95 0 00-4.944 4.944 4.95 4.95 0 004.944 4.944h17.357a3.738 3.738 0 013.734 3.734 3.738 3.738 0 01-3.734 3.734H11.472a.605.605 0 100 1.21h12.206a4.95 4.95 0 004.944-4.944 4.95 4.95 0 00-4.944-4.944z"/>
      </svg>
  )
}
