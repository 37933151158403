import React from "react"


export default function () {
  
  return  (
    <div className="footer">
      <a className="footer__link" href="https://github.com/ILokalin/fun-route" rel="noopener noreferrer" target="_blank">Ilya Lokalin. Test of React.JS 2019
      </a>
    </div>
  )
}
